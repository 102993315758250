<div class="app-section">
  <div class="header">
    <a href="https://sympti.quickrcare.com/" target="_blank" rel="noopener noreferrer">
      <img src="/assets/images/logo-sympti-white.svg" alt="Sympti Logo">
    </a>
  </div>
  <div class="content-body">
    <router-outlet></router-outlet>
  </div>
  <div class="footer">
    <a href="https://sympti.quickrcare.com/" target="_blank" rel="noopener noreferrer">
      ©{{year}} Sympti
    </a>
  </div>
</div>
