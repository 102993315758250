<div class="main-page-container">

  <div class="container">

    <div class="row justify-content-between">
      <div class="col-xl-6 col-lg-6 d-flex align-items-center mb-4">

        <div class="description">
          <h3>
            <img src="/assets/images/main_icon_1.svg" width="80" height="80" alt="">
            Visit Review
          </h3>
          <p *ngIf="showForm">
            Please let us know how your <span class="facility-name">{{ facilityData.name }}</span> visit was.
          </p>
          <p>
            <em class="cool-title">- The Sympti Team</em>
          </p>
        </div>

      </div>
      <div *ngIf="showSystemMessage" class="col-xl-5 col-lg-5">
        <div class="message-section">
          {{ systemMessage }}
        </div>
      </div>
      <div *ngIf="showForm" class="col-xl-5 col-lg-5">

        <form id="formId" [formGroup]="form">
          <div class="mb-4">
            <div class="progress-bar">
              <div class="progress-bar-value" [ngClass]="currentProgress()"></div>
            </div>
            <!-- STEP 1 -->
            <div *ngIf="step === 1" class="step 1">
              <div class="title mb-4">
                <strong>1 of 5</strong>
                How was your overall experience?
              </div>
              <div class="smailes-section mb-4">
                <ul>
                  <li *ngFor="let item of choisList; let i = index;">
                    <div class="container-smile">
                      <input type="radio" id="{{ item.id }}1" value="{{ item.value }}" name="question1" formControlName="question1">
                      <label class="radio smile_{{ i + 1 }}" for="{{ item.id }}1">
                        <span>{{ item.value }}</span>
                      </label>
                    </div>
                  </li>
                </ul>

                <div class="d-flex justify-content-between description">
                  <em>Very Bad</em>
                  <em>Excellent</em>
                </div>

                <div *ngIf="submitted && ctrlForm['question1'].errors" class="required-fild">
                  <div class="arrow"></div>
                  Please leave your rating above
                </div>
              </div>

              <div>
                <div class="textarea-wrap">
                  <label for="additional_message_label" [ngClass]="{'label-active': ctrlForm['description1'].value}">
                    Please provide some details...
                  </label>
                  <textarea
                    formControlName="description1"
                    id="additional_message_label"
                    class="form-control"
                    [ngClass]="{'textarea-active': ctrlForm['description1'].value}"
                    placeholder="Please provide some details..."></textarea>
                </div>
              </div>
            </div>

            <!-- STEP 2 -->
            <div *ngIf="step === 2" class="step 2">
              <div class="title mb-4">
                <strong>2 of 5</strong>
                Please rate your experience with the in person registration process.
              </div>
              <div class="smailes-section mb-4">
                <ul>
                  <li *ngFor="let item of choisList; let i = index;">
                    <div class="container-smile">
                      <input type="radio" id="{{ item.id }}2" value="{{ item.value }}" name="question2" formControlName="question2">
                      <label class="radio smile_{{ i + 1 }}" for="{{ item.id }}2">
                        <span>{{ item.value }}</span>
                      </label>
                    </div>
                  </li>
                </ul>

                <div class="d-flex justify-content-between description">
                  <em>Very Bad</em>
                  <em>Excellent</em>
                </div>

                <div *ngIf="submitted && ctrlForm['question2'].errors" class="required-fild">
                  <div class="arrow"></div>
                  Please leave your rating above
                </div>
              </div>

              <div>
                <div class="textarea-wrap">
                  <label for="additional_message_label" [ngClass]="{'label-active': ctrlForm['description2'].value}">
                    Additional details...
                  </label>
                  <textarea
                    formControlName="description2"
                    id="additional_message_label"
                    class="form-control"
                    [ngClass]="{'textarea-active': ctrlForm['description2'].value}"
                    placeholder="Additional details..."></textarea>
                </div>
              </div>
            </div>

            <!-- STEP 3 -->
            <div *ngIf="step === 3" class="step 3">
              <div class="title mb-4">
                <strong>3 of 5</strong>
                Please rate your experience with the medical staff.
              </div>
              <div class="smailes-section mb-4">
                <ul>
                  <li *ngFor="let item of choisList; let i = index;">
                    <div class="container-smile">
                      <input type="radio" id="{{ item.id }}3" value="{{ item.value }}" name="question3" formControlName="question3">
                      <label class="radio smile_{{ i + 1 }}" for="{{ item.id }}3">
                        <span>{{ item.value }}</span>
                      </label>
                    </div>
                  </li>
                </ul>

                <div class="d-flex justify-content-between description">
                  <em>Very Bad</em>
                  <em>Excellent</em>
                </div>

                <div *ngIf="submitted && ctrlForm['question3'].errors" class="required-fild">
                  <div class="arrow"></div>
                  Please leave your rating above
                </div>
              </div>

              <div>
                <div class="textarea-wrap">
                  <label for="additional_message_label" [ngClass]="{'label-active': ctrlForm['description3'].value}">
                    Additional details...
                  </label>
                  <textarea
                    formControlName="description3"
                    id="additional_message_label"
                    class="form-control"
                    [ngClass]="{'textarea-active': ctrlForm['description3'].value}"
                    placeholder="Additional details..."></textarea>
                </div>
              </div>
            </div>

            <!-- STEP 4 -->
            <div *ngIf="step === 4" class="step 4">
              <div class="title mb-4">
                <strong>4 of 5</strong>
                Would you recommend this facility to your friends and family?
              </div>
              <div class="smailes-section mb-4">

                <ul class="radio-checkbox-section">
                  <li>
                    <div class="checkbox_radio_container">
                      <input type="radio" id="no" name="question4" value="Yes" formControlName="question4">
                      <label class="radio" for="no"></label>
                      <label for="no" class="wrapper">Yes</label>
                    </div>
                  </li>
                  <li>
                    <div class="checkbox_radio_container">
                      <input type="radio" id="maybe" name="question4" value="No" formControlName="question4">
                      <label class="radio" for="maybe"></label>
                      <label for="maybe" class="wrapper">No</label>
                    </div>
                  </li>
                  <li>
                    <div class="checkbox_radio_container">
                      <input type="radio" id="probably" name="question4" value="Probably" formControlName="question4">
                      <label class="radio" for="probably"></label>
                      <label for="probably" class="wrapper">Probably</label>
                    </div>
                  </li>
                  <li>
                    <div class="checkbox_radio_container">
                      <input type="radio" id="sure" name="question4" value="Not sure" formControlName="question4">
                      <label class="radio" for="sure"></label>
                      <label for="sure" class="wrapper">Not sure</label>
                    </div>
                  </li>
                </ul>

                <div *ngIf="submitted && ctrlForm['question4'].errors" class="required-fild">
                  <div class="arrow"></div>
                  Please leave your rating above
                </div>
              </div>

              <div>
                <div class="textarea-wrap">
                  <label for="additional_message_label" [ngClass]="{'label-active': ctrlForm['description4'].value}">
                    Additional message
                  </label>
                  <textarea
                    formControlName="description4"
                    id="additional_message_label"
                    class="form-control"
                    [ngClass]="{'textarea-active': ctrlForm['description4'].value}"
                    placeholder="Additional message"></textarea>
                </div>
              </div>
            </div>

            <!-- STEP 5 -->
            <div *ngIf="step === 5" class="step 5">
              <div class="title mb-4">
                <strong>5 of 5</strong>
                How did you hear about this facility?
              </div>
              <div class="smailes-section mb-4">

                <ul class="radio-checkbox-section">
                  <li *ngFor="let item of answersHearAboutFacility; let i = index;">
                    <div class="checkbox_radio_container">
                      <input
                        id="question5_opt_{{ i }}"
                        type="checkbox"
                        name="question5"
                        value="{{ item.value }}"
                        formControlName="questionTemp"
                        (ngModelChange)="selectHearAboutFacility($event, item.value)">
                      <label class="checkbox" for="question5_opt_{{ i }}"></label>
                      <label for="question5_opt_{{ i }}" class="wrapper">{{ item.title }}</label>
                    </div>
                  </li>
                </ul>

                <div class="d-flex justify-content-start description">
                  <em>Multiple selections *</em>
                </div>

                <div *ngIf="submitted && ctrlForm['questionTemp'].errors" class="required-fild">
                  <div class="arrow"></div>
                  Please leave your rating above
                </div>
              </div>

            </div>
          </div>

        </form>

        <div *ngIf="showForm" class="d-flex justify-content-end align-items-center btn-section">
          <button *ngIf="this.step > 1" type="button" class="btn btn-light me-1" (click)="prevStep()">
            Prev
          </button>
          <button *ngIf="this.step !== 5" type="button" class="btn btn-warning" (click)="nextStep()">
            Next
          </button>
          <button *ngIf="this.step === 5" type="button" class="btn btn-warning" (click)="save(form.value, form.valid)">
            Submit
          </button>
        </div>

      </div>
    </div>

  </div>
</div>
